import React, { useContext, useEffect, useState } from 'react';
import "../assets/main.css";
import Header from '../Components/Header';
import "../assets/upcoming.css";
import { fetch_home } from '../api';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import moment from 'moment';
import User from '../Protected/context';

const Upcoming = () => {
  const [user, setUser] = useContext(User)
  const [cookies, setCookie, removeCookie] = useCookies(["g-pwd"])
  const [reservations, setReservations] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchHome = () => {
    setLoading(true)
    const obj = { "token": cookies["g-pwd"] }
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: fetch_home,
      headers: {
        'Content-Type': 'application/json'
      },
      data: obj
    };

    axios(config)
      .then(function (response) {
        setLoading(false)
        console.log(JSON.stringify(response.data));
        if (response.data.status === 200) {
          setReservations(response.data.reservations)
        }
      })
      .catch(function (error) {
        setLoading(false)
        console.log(error);
      });



  }

  useEffect(() => {
    fetchHome()
  }, [])


  return (
    <div className='container-upcoming'>
      <div className='container-heading'>
        <h2>Welcome {user !== null && (
          <>
            {user.name}
          </>
        )}!</h2>
      </div>
      <div className='short-desc'>
        <p>Roamhome permits it's homeowners to earn money by referring other homeowners to Roamhome.  Do get in touch for details.</p>
      </div>
      <div className='upcoming-section'>
        <div className='upcoming-header'>
          <h3>Upcoming Reservations</h3>
        </div>
        <div className='container-reservations'>
          {loading && (
            <div className='loading'>
              Loading...
            </div>
          )}
          {!loading && (
            <div className='container-reservations-item'>
              {reservations.length === 0 && (
                <div className='no-upcoming-container'>
                  No Reservations Yet
                </div>
              )}
              {reservations.map((item, idx) => (
                <div className='reservation-item'>
                  <div className='property-details'>
                    <div className='property-img'>
                      <img src={`https://d2sg0yxuzrccbw.cloudfront.net/${item.featured_img}`} alt="ef" className='property-img' />
                    </div>
                    <div className='property-name'>
                      <div className='arriving-in'>
                        {moment(item.date_from).diff(moment().startOf('day'), 'days') === 0 ? "Arriving Today" : `Arriving in ${moment(item.date_from).diff(moment().startOf('day'), 'days')} days`}
                      </div>
                      <div className='homename'>{item.internal_property_name}</div>
                      <div className='roamhome-name'>{item.property_name}</div>
                    </div>
                  </div>

                  <div className='guest-items'>
                    <div className='guest-name'>
                      {item.billed_to}
                    </div>
                    <div className='guest-details'>
                      {item.num_guests > 1 ? `${item.num_guests} guests` : `${item.num_guests} guest`} &#x2022; {parseInt(moment(item.date_to).diff(moment(item.date_from), 'days')) === 1 ? `1 night` : `${moment(item.date_to).diff(moment(item.date_from), 'days')} nights`}
                    </div>
                    <div className='checkin-details'>
                      <div className='checkin'>
                        {moment(item.date_from).format("DD MMMM YYYY")} <span className='arrow'>&#10230;</span> {moment(item.date_to).format("DD MMMM YYYY")}
                      </div>
                      <div className='show'>
                        <button>SHOW MORE</button>
                      </div>
                    </div>
                  </div>
                  <div className='payout-details'>
                    <div className='payout-on'>
                      PAYOUT ON {moment(item.payout, "YYYY-MM-DD").format("DD MMMM YYYY")}
                    </div>
                    <div className='payout-amt'>
                      {/* {Intl.NumberFormat(item.homeowner_share)} */}
                      INR {Intl.NumberFormat('en-IN', { maximumSignificantDigits: 5 }).format(item.homeowner_share)}
                      {/* INR 12,783.01 */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className='container-reservation-stats'>

          </div>

        </div>
      </div>
    </div>
  );
};

export default Upcoming;