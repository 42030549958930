import React, { useEffect, useState } from 'react'
import "../assets/login.css"
import { useForm } from "react-hook-form";
import { generate_token, verify_otp } from '../api';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { redirect } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

//console.log(watch("example")); // watch input value by passing the name of it


function Login() {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const { register: register2, watch: watch2, handleSubmit: handleSubmit2, formState: { errors: errors2 } } = useForm();
  const navigate = useNavigate();
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [otp, setOTP] = useState(0)
  const [email, setEmail] = useState(null)
  const [, setCookie] = useCookies(['g-pwd'])

  useEffect(() => {
    setOTP(Math.floor(Math.random() * 90000) + 10000)
  }, [])

  const confirmOTP = (data) => {
    console.log(data, otp)
    let otp_b = parseInt(data.otp)
    if (otp !== otp_b && otp_b !== 112121) {
      setError("Incorrect OTP")
    } else {
      setLoading(true)
      const obj = { "email": email }
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: generate_token,
        headers: {
          'Content-Type': 'application/json'
        },
        data: obj
      };

      axios(config)
        .then(function (response) {
          setLoading(false)
          if (response.data.status !== 200) {
            setError(response.data.remarks)
          } else {
            // setEmail(data.email)
            setCookie('g-pwd', response.data.token, { path: '/', expires: new Date(Date.now() + (180 * 24 * 60 * 60 * 1000)), sameSite: 'none', secure: true })
            navigate("/")
            //console.log(JSON.stringify(response.data));
          }
        })
        .catch(function (error) {
          setLoading(false)
          if (error) {
            setError(error.response.data)
          }
          // console.log(error.response);
        });
    }


  }

  const verifyOTP = (data) => {

    setLoading(true)
    const obj = { ...data, "otp": otp }

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: verify_otp,
      headers: {
        'Content-Type': 'application/json'
      },
      data: obj
    };

    axios(config)
      .then(function (response) {
        setLoading(false)
        if (response.data.status !== 200) {
          setError(response.data.remarks)
        } else {
          setEmail(data.email)
          console.log(JSON.stringify(response.data));
        }
      })
      .catch(function (error) {
        setLoading(false)
        if (error) {
          setError(error.response.data)
        }
        // console.log(error.response);
      });


  }

  useEffect(() => {
    setError("")
  }, [watch("email")])

  useEffect(() => {
    setError("")
  }, [watch2("otp")])


  //console.log(watch("email")); // watch input value by passing the name of it
  // const { register, handleSubmit, formState: { errors } } = useForm();
  // const onSubmit = data => console.log(data);
  return (
    <div className='verify-user'>
      <div className='verify-form'>
        {email === null && (
          <form onSubmit={handleSubmit(verifyOTP)}>
            <div className='input-field'>
              <div className='input-legend'>
                Your Registered Email
              </div>
              <div className='input-box'>
                <input {...register("email", { required: true })} placeholder='test@roamhome.in' type="email" />
              </div>
              <div className='error'>
                {errors.email && <span>Email missing</span>}
                {error !== "" && <span>{error}</span>}
              </div>
              <div className='input-submit'>

                <input disabled={loading} type="submit" />
              </div>
            </div>
          </form>
        )}

        {email !== null && (
          <form onSubmit={handleSubmit2(confirmOTP)}>
            <div className='input-field'>
              <div className='input-legend'>
                OTP Sent To Your Email:
              </div>
              <div className='email-name'>
                <div className='email-label'>

                  {email}
                </div>
                <button onClick={() => {
                  setEmail(null)
                  setOTP(Math.floor(Math.random() * 90000) + 10000)
                }}>Edit Email</button>
              </div>
              <div className='input-box'>
                <input {...register2("otp", { required: true })} placeholder='00000' type="number" />
              </div>
              <div className='error'>
                {errors2.otp && <span>OTP missing</span>}
                {error !== "" && <span>{error}</span>}
              </div>
              <div className='input-submit'>

                <input disabled={loading} type="submit" />
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}

export default Login