import React, {useState, useEffect, useContext} from 'react';
import { Navigate } from 'react-router-dom'
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { check_account } from '../api';
import User from './context';

const ProtectedRoute = ({ children }) => {
    const [cookies] = useCookies(['g-pwd']);
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useContext(User)

    async function checkAuthentication() {
        console.log(cookies['g-pwd'])
        if(cookies['g-pwd']) {
          const obj = { "token": cookies['g-pwd'] }
          const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: check_account,
            headers: {
              'Content-Type': 'application/json'
            },
            data: obj
          };
  
  
          axios(config)
          .then(function (response) {
            
            if (response.data.status !== 200) {
              setIsAuthenticated(false);
            } else {
              setUser(response.data)
              console.log(response.data)
              // setEmail(data.email)
              setIsAuthenticated(true);
              //console.log(JSON.stringify(response.data));
            }
          })
          .catch(function (error) {
              setIsAuthenticated(false);
            
            // console.log(error.response);
          });
        } else {
          setIsAuthenticated(false)
        }

        setIsLoading(false);
    }


    useEffect(() => {     
        checkAuthentication();
      }, [cookies["g-pwd"]]);


      useEffect(() => {
        console.log(isAuthenticated)
      }, [isAuthenticated])

    if (isLoading && (isAuthenticated === null)) {
        return <div>Loading...</div>;
      }
    

    if (isAuthenticated === false) {
        return <Navigate to="/login" replace />
    }

    if(isAuthenticated === true) {
        return children
    }


};

export default ProtectedRoute;