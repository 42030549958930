import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { fetch_home } from '../api'
import "../assets/listings.css"

function Listings() {
    const [cookies, setCookie, removeCookie] = useCookies(["g-pwd"])
    const [reservations, setReservations] = useState([])
    const [loading, setLoading] = useState(false)
  
    const fetchHome = () => {
      setLoading(true)
      const obj = { "token": cookies["g-pwd"] }
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: fetch_home,
        headers: {
          'Content-Type': 'application/json'
        },
        data: obj
      };
  
      axios(config)
        .then(function (response) {
          setLoading(false)
          console.log(JSON.stringify(response.data));
          if (response.data.status === 200) {
            setReservations(response.data.homes)
          }
        })
        .catch(function (error) {
          setLoading(false)
          console.log(error);
        });
  
  
  
    }
  
    useEffect(() => {
        console.log("disturb")
      fetchHome()
    }, [])
    return (
        <div className='listing-container'>
            {loading && (
                <div className='loading'>Loading...</div>
            )}
            {!loading && (
                <>
            {reservations.map((item, idx) => (
              <a href={`/payout?listing_id=${item.listing_id}`}>
                <div className='listing-item'>
                    <div className='listing-item-img'>
                        <img src={`https://d2sg0yxuzrccbw.cloudfront.net/${item.featured_img}`} alt="r" />
                    </div>
                    <div className='listing-content'>
                        <div className='listing-name'>
                            <div className='listing-name-roamhome'>
                                {item.property_title}
                            </div>
                            <div className='listing-owner'>{item.internal_property_name}</div>
                        </div>
                        <div className='guest-allowed'>
                            Guests: {item.num_of_guests + item.additional_guests}
                        </div>
                    </div>
                </div>
              </a>
            ))}
            </>
            )}
        </div>
    )
}

export default Listings