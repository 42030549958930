import React, { useEffect, useState } from "react";
import Moment from "moment";
import MomentUtils from "@date-io/moment";
import "../assets/calendar-test.scss"
import { extendMoment } from "moment-range";
// import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
// import { useDispatch, useSelector } from "react-redux";

//API ENDPOINTS CALENDAR
//import { Delete_Block_Date, Add_Block_Date } from "../../../api/private/index";

//UI
import { Grid, InputAdornment, NativeSelect, Input } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import SearchIcon from "@material-ui/icons/Search";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Skeleton from "@material-ui/lab/Skeleton";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

//custom components
import useDidMountEffect from "../hooks/useDidMountEffect";
import BlockedPeriod from "../Components/BlockedPeriod";
import AddBlockAndCustomPeriod from "../Components/AddBlockAndCustomPeriod";
//import Reservation from "../../../components/calendar/test/Reservation";
// import httpClientPrivate, {
//   httpListingClient,
// } from "../../../api/httpClientPrivate";
import axios from "axios";
import { useCookies } from "react-cookie";
import { block_date, fetch_calendar } from "../api";

//starting of calendar
const past = new Date("January 1, 2019");

//New API ENDPOINTS
const Fetch_Reservations = "private/calendar/calendarReservations";
const Fetch_Other_Prices = "private/calendar/getOtherPrices";

function Calendar() {
  const [cookies, setCookie, removeCookie] = useCookies(["g-pwd"])
  const [cellSizeOffset, setSizeOffset] = useState(5)
  //snackbar
  // const { enqueueSnackbar } = useSnackbar();

  const moment = extendMoment(Moment);

  //listings data
  const [listings, setlistings] = React.useState([]);
  const [listings_loading, setListings_loading] = React.useState(false);

  //other prices
  const [otherPrices, setOtherPrices] = React.useState(null);

  //auth
  //  const { token } = useSelector((state) => state.auth);
  const token = cookies["g-pwd"];

  //search state
  const [currentstate, setcurrentstate] = React.useState("Goa");

  //current date
  const [selectedDate, handleDateChange] = React.useState(
    moment().startOf("month")
  );

  //search property
  const [searchProperty, setSearchProperty] = React.useState("");

  //calendar timeline
  const calendarRef = React.useRef(null);

  //listing timeline
  const itemsRef = React.useRef([]);

  //calendar component
  const windowRef = React.useRef(null);


  //dialog
  //add blocked period form
  const { register, handleSubmit, errors } = useForm();

  const [showBlockedPeriod, setShowBlockedPeriod] = React.useState(false);
  const [blockedPeriodData, setBlockedPeriodData] = React.useState(null);

  const [showAddBlockAndPrice, setShowAddBlockAndPrice] = React.useState(null);
  const [addBlockAndPriceData, setAddBlockAndPriceData] = React.useState(null);

  const [showReservation, setShowReservation] = React.useState(false);
  const [reservationData, setReservationData] = React.useState(null);

  //auth token
  //console.log(token);

  //API Calls

  const getListings2 = () => {
    setListings_loading(true);

    handleScroll(-calendarRef.current.scrollLeft);
    const obj = {
      token: token,
      from_date: moment(selectedDate)
        .subtract(1, "month")
        .endOf("month")
        .subtract(5, "days")

        .format("YYYY-MM-DD"),
      to_date: moment(selectedDate)
        .add(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD"),
    }


    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: fetch_calendar,
      headers: {
        'Content-Type': 'application/json'
      },
      data: obj
    };

    //console.log(config)

    axios(config)
      .then(function (response) {
        setListings_loading(false);
        setlistings(response.data.data);
        // offsetToday();
      })
      .catch((er) => {
        setListings_loading(false);
      })
  }


  const addBlockedPeriod = ({ property_id, date_to, date_from, remarks }) => {
    const obj = {
      listing_id: property_id,
      start_date: moment(date_from).format("DD MMM YYYY"),
      end_date: moment(date_to).format("DD MMM YYYY"),
      remarks: remarks,
      token: token
    }

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: block_date,
      headers: {
        'Content-Type': 'application/json'
      },
      data: obj
    }

    axios(config)
      .then((response) => {
        if (response.data.status === 200) {
          setShowAddBlockAndPrice(null);
          setAddBlockAndPriceData(null);
          reloader();
        } else {
          alert("Error Adding Block, make sure you selected non-overlapping dates.")
        }
      })
      .catch((err) => {
        alert(err.message)
      })

    console.log(obj)

  };











  //UI Updates


  const reloader = () => {
    setListings_loading(true);
    //sethasmore(true);
    //if (observer.current) observer.current.disconnect();
    setlistings([]);
    // setcurrentindex_listings(0);
    const payload = { token: token };
    getListings2(payload);
  };

  const offsetToday = () => {
    if (!moment().isSame(selectedDate, "month")) {
      return;
    }
    let timelineViewSize =
      Math.floor(calendarRef.current.parentElement.offsetWidth / 60);

    let offsetDate = moment().endOf("month").subtract(timelineViewSize, "days");

    console.log(offsetDate.format("YYYY-MM-DD"));

    if (moment().isSameOrBefore(offsetDate)) {
      console.log(moment().diff(moment().startOf("month"), "days"));
      handleScroll(60 * moment().diff(moment().startOf("month"), "days"));
    } else {
      console.log(moment(offsetDate).diff(moment().startOf("month"), "days"));
      handleScroll(
        60 * (moment(offsetDate).diff(moment().startOf("month"), "days"))
      );
    }
  };

  React.useEffect(() => {
    if (listings.length !== 0 && moment().isSame(selectedDate, "month")) {
      offsetToday()
    }
  }, [listings])

  React.useEffect(() => {
    window.innerWidth <= 650 ? setSizeOffset(0) : setSizeOffset(5)
    if (listings.length === 0) {
      const payload = { token: token };
      getListings2(payload);
    }

    return () => {
      //dispatch(clearOnUnmount())
      setListings_loading(false);
      setlistings([]);
      //setcurrentindex_listings(0);
    };
  }, []);

  useDidMountEffect(() => {

    reloader();
  }, [selectedDate]);



  const handleScroll = (length, behavior = "auto") => {
    //console.log(calendarRef.current.scrollLeft);
    calendarRef &&
      calendarRef.current.scroll({
        top: 0,
        left: calendarRef.current.scrollLeft + length - calendarRef.current.scrollLeft % 60,
        behavior,
      });

    //console.log(calendarRef.current.scrollWidth);
    //console.log(calendarRef.current.clientWidth);
    //console.log(calendarRef.current.scrollLeft);

    itemsRef.current.forEach((item) => {
      item && item.scroll({
        top: 0,
        left: item.scrollLeft + length - calendarRef.current.scrollLeft % 60,
        behavior,
      });
    })

    /**/
    //console.log(calendarRef.current.scrollLeft);
  };


  // useEffect(() => {
  //   console.log(itemsRef,"j")
  // },[itemsRef])

  const handleChangeDay = (length) => {
    if (listings_loading) return;
    handleScroll(length, "smooth");
    let timelineViewSize =
      Math.floor(calendarRef.current.parentElement.offsetWidth / 60) - 3;
    let currentOffset = Math.floor(calendarRef.current.scrollLeft / 60);
    console.log(moment(selectedDate).daysInMonth() - timelineViewSize - 1);
    console.log(currentOffset);
    if (currentOffset <= 0 && length < 0) {
      handleDateChange(moment(selectedDate).subtract(1, "months"));
    } else if (
      moment(selectedDate).daysInMonth() - timelineViewSize - 1 <=
      currentOffset &&
      length > 0
    ) {
      handleDateChange(moment(selectedDate).add(1, "months"));
    }
  };

  const handleChangeMonth = (change) => {
    if (change >= 0) {
      handleDateChange(moment(selectedDate).add(1, "month"));
    } else {
      handleDateChange(moment(selectedDate).subtract(1, "months"));
    }
  };

  // Data Presentation
  const source_to_period = (source) => {
    switch (source) {
      case "Roamhome":
        return "period-roamhome";
      case "AIRBNB":
        return "period-airbnb";
      case "BOOKINGDOTCOM":
        return "period-booking_dot_com";
      case "GOIBIBO":
        return "period-goibibo";
      default:
        return "period-roamhome";
    }
  };

  const openFullscreen = () => {
    if (windowRef.current.requestFullscreen) {
      windowRef.current.requestFullscreen();
    } else if (windowRef.current.webkitRequestFullscreen) {
      /* Safari */
      windowRef.current.webkitRequestFullscreen();
    } else if (windowRef.current.msRequestFullscreen) {
      /* IE11 */
      windowRef.current.msRequestFullscreen();
    }
  };

  document.onkeydown = (event) => {
    //console.log(
    //  `Key: ${event.key} with keycode ${event.keyCode} has been pressed`
    //);

    if (event.key === "ArrowLeft") {
      handleChangeDay(-60);
    } else if (event.key === "ArrowRight") {
      handleChangeDay(60);
    } else if (event.key === "Tab") {
      openFullscreen();
    }
  };

  //Dialog handlers
  const handleBlockedPeriod = (data) => {
    setBlockedPeriodData(data);
    setShowBlockedPeriod(true);
  };

  const addBlockedPeriodAndCustomPrice = (e, addblock_and_pricedata) => {
    setShowAddBlockAndPrice(e.currentTarget);
    setAddBlockAndPriceData(addblock_and_pricedata);
  };

  const handleReservation = (reservation_data) => {
    setShowReservation(true);
    setReservationData(reservation_data);
  };

  return (
    <>
      {
        //dialogs
      }
      <BlockedPeriod
        show={showBlockedPeriod}
        setShow={setShowBlockedPeriod}
        data={blockedPeriodData}
        setData={setBlockedPeriodData}
        //handleDelete={deleteBlockedPeriod}
      />
      <AddBlockAndCustomPeriod
        show={showAddBlockAndPrice}
        setShow={setShowAddBlockAndPrice}
        data={addBlockAndPriceData}
        setData={setAddBlockAndPriceData}
        register={register}
        handleSubmit={handleSubmit}
        addBlockedPeriod={addBlockedPeriod}
      />
      {/* <Reservation
        show={showReservation}
        setShow={setShowReservation}
        data={reservationData}
      /> */}
      {
        //calendar component
      }
      <Grid ref={windowRef} className="calendar-test" container>
        <Grid className="top-ribbon" item xs={12}>
          <Grid container>
            {
              //property filters
            }
            <Grid item style={{ width: 300 }} className="col filter">
              {
                //search filter
              }
              <div>Property</div>
              <OutlinedInput
                id="outlined-basic"
                placeholder="Search Properties"
                className="property-search"
                value={searchProperty}
                onChange={(e) => setSearchProperty(e.target.value)}
                inputProps={{
                  style: {
                    padding: 0,
                    fontSize: 16,
                  },
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon className="filter-icon" />
                  </InputAdornment>
                }
              />
              {
                //state filter
              }

            </Grid>
            {
              //calendar timeline
            }
            <Grid style={{ overflowX: "hidden" }} item xs>
              <Grid container>
                <Grid className="calendar" item xs={12}>
                  <div className="calendar-row">
                    <div>
                      <button
                        className="calendar-btn"
                        onClick={(e) => {
                          if (listings_loading) {
                            return;
                          }
                          handleChangeMonth(-1);
                        }}
                      >
                        <ChevronLeftIcon />
                        <span>
                          {moment(selectedDate)
                            .subtract(1, "months")
                            .format("MMMM")}
                        </span>
                      </button>
                    </div>
                    <div>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                          openTo="year"
                          minDate={past}
                          views={["year", "month"]}
                          value={selectedDate}
                          onChange={handleDateChange}
                          inputVariant="filled"
                          TextFieldComponent={(props) => {
                            return (
                              <Input
                                type="text"
                                onClick={props.onClick}
                                value={props.value}
                                onChange={handleDateChange}
                                className="timeline-select"
                                endAdornment={
                                  <InputAdornment position="end">
                                    <KeyboardArrowDownIcon />
                                  </InputAdornment>
                                }
                              />
                            );
                          }}
                        ></DatePicker>
                      </MuiPickersUtilsProvider>
                    </div>
                    <div>
                      <button
                        className="calendar-btn"
                        onClick={() => {
                          if (listings_loading) {
                            return;
                          }
                          handleChangeMonth(1);
                        }}
                      >
                        <span>
                          {moment(selectedDate).add(1, "months").format("MMMM")}
                        </span>
                        <ChevronRightIcon />
                      </button>
                    </div>
                  </div>
                </Grid>
                <Grid className="timeline" item xs={12}>
                  <div className="timeline-row" ref={calendarRef}>
                    <button
                      className="timeline-btn-left"
                      onClick={() => handleChangeDay(-60)}
                    >
                      <ChevronLeftIcon />
                    </button>

                    {Array.from(
                      moment
                        .range(
                          moment(selectedDate),
                          moment(moment(selectedDate).add(1, "months"))
                        )
                        .by("day"),
                      (d) => (
                        <div
                          className={`cell ${moment(d).isSame(moment(), "day") && "day-active"
                            }`}
                          key={moment(d).format("DD-MM-YYYY")}
                        >
                          <div style={{ fontWeight: "bold" }}>
                            {moment(d).format("ddd")}
                          </div>
                          <br />
                          {parseInt(moment(d).format("DD")) < 10
                            ? moment(d).format("DD").replace("0", "")
                            : moment(d).format("DD")}
                        </div>
                      )
                    )}

                    <button
                      className="timeline-btn-right"
                      onClick={() => handleChangeDay(60)}
                    >
                      <ChevronRightIcon />
                    </button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {
          //listings
        }
        <Grid className="listing" item style={{ overflowX: "hidden" }} xs={12}>
          {
            //listing loading
          }
          {listings_loading &&
            Array(10)
              .fill(null)
              .map(() => {
                return (
                  <div style={{ marginTop: 30 }}>
                    <Skeleton
                      animation="wave"
                      variant="rectangular"
                      width={60 * 30}
                      height={75}
                    />
                  </div>
                );
              })}
          {
            //listings
          }
          {!listings_loading &&
            listings
              .filter(
                (listing) =>
                  listing.property_title
                    .toLowerCase()
                    .includes(searchProperty.toLowerCase()) ||
                  searchProperty === ""
              )
              .map((item, idx) => {
                return (
                  <div
                    className="listing-timeline-wrapper"
                    style={{ position: "relative" }}
                  >
                    <div className="listing-name">{item.property_title}</div>
                    <div
                      key={idx}
                      ref={(element) => {
                        itemsRef.current[idx] = element;
                      }}
                      className="listing-timeline"
                    >
                      {
                        //calendar-cells
                      }
                      <div className="listing-timeline-row">
                        {Array.from(
                          moment
                            .range(
                              moment(selectedDate)
                                .subtract(1, "month")
                                .endOf("month")
                                .subtract(cellSizeOffset, "days"),
                              moment(
                                moment(selectedDate)
                                  .add(1, "months")
                                  .startOf("month")
                                  .add(2, "days")
                              )
                            )
                            .by("day"),
                          (d) => (
                            <div
                              className={`listing-cell ${moment(d).isSame(moment(), "day") &&
                                "listing-cell-active"
                                }`}
                              key={moment(d).format("DD-MM-YYYY")}
                              id={moment(d).format("DD-MM-YYYY")}
                              onClick={(e) => {
                                //e.stopPropagation();
                                addBlockedPeriodAndCustomPrice(e, {
                                  property_id: item.listing_id,
                                  property_name: item.property_title,
                                  active_day: moment(d).format("YYYY-MM-DD"),
                                });
                              }}
                            >
                              <div className="cell-padding"></div>
                              <div className="cell">
                                <div className="cell-content">
                                  {!item.blocked_dates.find((blocked_date) =>
                                    moment(d).isBetween(
                                      blocked_date.ota_date_from,
                                      blocked_date.ota_date_to,
                                      "day",
                                      "[]"
                                    )
                                  ) &&
                                    !item.reservations.find((reservation) =>
                                      moment(d).isBetween(
                                        reservation.date_from,
                                        reservation.date_to,
                                        "day",
                                        "[]"
                                      )
                                    )}
                                </div>
                              </div>
                            </div>
                          )
                        )}
                        {
                          //Reserved Dates
                        }
                        {item.reservations
                          .sort((a, b) => moment(a.date_from).diff(b.date_from))
                          .map((period, period_index) => {
                            return (
                              <div
                                key={period_index}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleReservation(period);
                                }}
                                className={
                                  "booking_source" in period
                                    ? source_to_period(period.booking_source)
                                    : "period-roamhome"
                                }
                                style={{
                                  left: `${moment(period.date_from).diff(
                                    moment(selectedDate)
                                      .subtract(1, "month")
                                      .endOf("month")
                                      .subtract(cellSizeOffset + 1, "days"),
                                    "days"
                                  ) > 0
                                    ? (moment(period.date_from).diff(
                                      moment(selectedDate)
                                        .subtract(1, "month")
                                        .endOf("month")
                                        .subtract(cellSizeOffset, "days"),
                                      "days"
                                    ) +
                                      1) *
                                    60 +
                                    30
                                    : moment(period.date_from).diff(
                                      moment(selectedDate)
                                        .subtract(1, "month")
                                        .endOf("month")
                                        .subtract(cellSizeOffset, "days"),
                                      "days"
                                    ) *
                                    60 +
                                    30
                                    }px`,
                                  width: `${moment
                                    .duration(
                                      moment(period.date_to).diff(
                                        moment(period.date_from),
                                        "days"
                                      ),
                                      "days"
                                    )
                                    .asDays() === 0
                                    ? 30
                                    : moment
                                      .duration(
                                        moment(period.date_to).diff(
                                          moment(period.date_from),
                                          "days"
                                        ),
                                        "days"
                                      )
                                      .asDays() * 60
                                    }px`,
                                }}
                              >
                                <div className="period-body">
                                  <div className="period-icon">
                                    <CheckCircleIcon className="period-icon-success" />
                                  </div>
                                  <div className="period-content">
                                    <span className="period-customer-name">
                                      {period.billed_to}
                                    </span>
                                    <span className="period-range">
                                      {moment(period.date_from).format(
                                        "DD-MMM"
                                      )}
                                      -
                                      {moment(period.date_to).format(
                                        "DD MMM , YYYY"
                                      )}
                                    </span>
                                    <span className="period-price">
                                      ₹
                                      {parseFloat(
                                        period.homeowner_amt
                                      ).toFixed(2)}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                        {
                          // Blocked dates
                        }
                        {item.blocked_dates.map(
                          (blocked_period, blocked_index) => {
                            return (
                              <div
                                key={blocked_index}
                                className="period-blocked"
                                data-event-id={blocked_period.event_id}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleBlockedPeriod(blocked_period);
                                }}
                                style={{
                                  left: `${moment(blocked_period.ota_date_from).diff(
                                    moment(selectedDate)
                                      .subtract(1, "month")
                                      .endOf("month")
                                      .subtract(cellSizeOffset + 1, "days"),
                                    "days"
                                  ) > 0
                                    ? (moment(
                                      blocked_period.ota_date_from
                                    ).diff(
                                      moment(selectedDate)
                                        .subtract(1, "month")
                                        .endOf("month")
                                        .subtract(cellSizeOffset, "days"),
                                      "days"
                                    ) +
                                      1) *
                                    60
                                    : moment(
                                      blocked_period.ota_date_from
                                    ).diff(
                                      moment(selectedDate)
                                        .subtract(1, "month")
                                        .endOf("month")
                                        .subtract(cellSizeOffset, "days"),
                                      "days"
                                    ) * 60
                                    }px`,
                                  width: `${(moment
                                    .duration(
                                      moment(blocked_period.ota_date_to).diff(
                                        moment(blocked_period.ota_date_from),
                                        "days"
                                      ),
                                      "days"
                                    )
                                    .asDays() +
                                    1) *
                                    60
                                    }px`,
                                }}
                              >
                                <div className="period-body">
                                  {Array.apply(null, {
                                    length:
                                      moment
                                        .duration(
                                          moment(
                                            blocked_period.ota_date_to
                                          ).diff(
                                            moment(
                                              blocked_period.ota_date_from
                                            ),
                                            "days"
                                          ),
                                          "days"
                                        )
                                        .asDays() + 1,
                                  }).map((_, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="period-blocked-icon"
                                      ></div>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
        </Grid>
      </Grid>
    </>
  );
}

export default Calendar;
