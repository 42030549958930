import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoute from './Protected/Protected';
import Home from './Pages/Upcoming';
import Login from './Pages/Login';
import Header from './Components/Header';
import Upcoming from './Pages/Upcoming';
import Listings from './Pages/Listings';
import Calendar from './Pages/Calendar';
import PayoutSheet from './Pages/Payout';
import PayoutSheetPublic from './Pages/PayoutPublic';

const RoutesM = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(true);


    const handleLogin = () => {
        setIsAuthenticated(true);
    };

    const handleLogout = () => {
        setIsAuthenticated(false);
    };

    return (


        <Routes>
            <Route path="/" element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                    <Upcoming />
                </ProtectedRoute>
            } />
            <Route path="/homes" element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                    <Listings />
                </ProtectedRoute>
            } />

            <Route path="/calendar" element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                    <Calendar />
                </ProtectedRoute>
            } />

            <Route path="/payout" element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                    <PayoutSheet />
                </ProtectedRoute>
            } />

            <Route path="/login" element={
                <Login />
            } />

            <Route path="/payout_public" element={
                <PayoutSheetPublic/>
            } />

        </Routes>

    );
};

export default RoutesM;