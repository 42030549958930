import React from "react";
import moment from "moment";
import {
  Dialog,
  Grid,
  FormControl,
  TextField,
  Divider,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";

const AddBlockAndCustomPeriod = ({
  show,
  setShow,
  data,
  setData,
  register,
  handleSubmit,
  addBlockedPeriod,
}) => {
  //console.log(data);
  return (
    <Dialog
      className="calendar-test"
      open={Boolean(show)}
      onClose={() => {
        setShow(null);
        setData(null);
      }}
      keepMounted
    >
      <div className="calendar-dialog ">
        {data === null && (
          <>
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
          </>
        )}
        {data !== null && (
          <>
            <Grid container style={{ marginBottom: "10px" }}>
              <Grid item xs={11}>
                <h2>{data.property_name}</h2>
              </Grid>
              <Grid item xs={1}>
                <button
                  className="dialog-btn-close"
                  onClick={() => {
                    setShow(null);
                    setData(null);
                  }}
                >
                  <CloseIcon />
                </button>
              </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={2} style={{ marginTop: "20px" }}>
              <Grid item xs={12}>
                <h3>Reserve dates</h3>
              </Grid>
              
            
            </Grid>
            <form
              onSubmit={handleSubmit(addBlockedPeriod)}
              autoComplete="off"
            >
              <Grid
                container
                alignItems="flex-end"
                style={{ marginTop: "20px" }}
                spacing={1}
              >
                <Grid item xs={5}>
                  <div className="edit-box-content" style={{ width: "100%" }}>
                    <div className="form-elements">
                      <FormControl fullWidth>
                        <TextField
                          className="date"
                          label="Block start date"
                          type="date"
                          {...register('date_from', { required: true })}
                          name="date_from"
                          //disabled={true}
                          //onChange={(elm) => setBlockStart(elm.target.value)}
                          defaultValue={moment(data.active_day).format(
                            "YYYY-MM-DD"
                          )}
                          inputProps={{ ...{ tag: "start_date" } }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                      <input
                        {...register('property_id', { required: true })}
                        // ref={register({ required: true })}
                        value={data.property_id}
                        name="property_id"
                        type="hidden"
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={5}>
                  <div className="edit-box-content" style={{ width: "100%" }}>
                    <div className="form-elements">
                      <FormControl fullWidth>
                        <TextField
                          className="date"
                          label="Block end date"
                          type="date"
                          {...register('date_to', { required: true })}
                          // inputRef={register({ required: true })}
                          name="date_to"
                          //onChange={(elm) => setBlockEnd(elm.target.value)}
                          //value={blockend}
                          defaultValue={moment(data.active_day)
                            .add(1, "days")
                            .format("YYYY-MM-DD")}
                          inputProps={{ ...{ tag: "end_date" } }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={10}>
                  <div className="edit-box-content" style={{ width: "100%" }}>
                    <div className="form-elements">
                      <FormControl fullWidth>
                        <TextField
                          className="remarks"
                          label="Remarks"
                          type="text"
                          {...register("remarks", { required: true })}
                          // inputRef={register({ required: true })}
                          name="remarks"
                          //onChange={(elm) => setBlockEnd(elm.target.value)}
                          //value={blockend}
                          inputProps={{ ...{ tag: "remarks" } }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <button type="submit" name="submit">
                    Add
                  </button>
                </Grid>
              </Grid>
            </form>
          </>
        )}
      </div>
    </Dialog>
  );
};

export default AddBlockAndCustomPeriod;
