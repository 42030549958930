import React from "react";
import {
  Dialog,
  Grid,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";

const BlockedPeriod = ({ show, setShow, data, setData, handleDelete }) => {
  return (
    <Dialog
      open={show}
      onClose={() => {
        setShow(false);
        setData(null);
      }}
      className="calendar-test"
      keepMounted
    >
      {
        data == null && (
          <>
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
          </>
        ) 
      }
      {data && (
        <div className="calendar-dialog">
          <Grid container>
            <Grid item xs={11}>
              <h2>Blocked dates</h2>
            </Grid>
            <Grid item xs={1}>
              <button
                className="dialog-btn-close"
                onClick={() => {
                  setShow(false);
                  setData(null);
                }}
              >
                <CloseIcon />
              </button>
            </Grid>
          </Grid>
          <Grid container></Grid>
          <Grid container spacing={2}>
            <Grid item>
              <div className="blocked-dates">
                From: {moment(data.ota_date_from).format("DD MMMM YYYY")}
              </div>
            </Grid>
            <Grid item>
              <div className="blocked-dates">
                To: {moment(data.ota_date_to).format("DD MMMM YYYY")}
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            className="blocked-content"
            alignItems="center"
          >
            <Grid item xs={6}>
              <p>
                Remarks: <br />
                {data.remarks}
              </p>
            </Grid>
            <Grid item xs={6}>
              <p>
                Added by: <br /> {data.added_by}
              </p>
            </Grid>
          </Grid>
          
        </div>
      )}
    </Dialog>
  );
};

export default BlockedPeriod;
