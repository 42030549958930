import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './Components/Header';
import User from './Protected/context';
import RoutesM from './Routes';
import { CookiesProvider } from 'react-cookie';

const App = () => {
  const [user, setUser] = useState(null);
  useEffect(() => {
    console.log("trigger when route change")
  }, [])

  // const handleLogin = () => {
  //   setIsAuthenticated(true);
  // };

  // const handleLogout = () => {
  //   setIsAuthenticated(false);
  // };

  return (
    <>
      <BrowserRouter>
        <User.Provider value={[user, setUser]}>
          <CookiesProvider>
            <Header />
            <RoutesM />
          </CookiesProvider>
        </User.Provider>
      </BrowserRouter>
    </>
  );
};

export default App;