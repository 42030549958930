import React from "react";
import {
    Grid,
    Dialog,
    FormControl,
    Divider,
    Input,
    InputLabel,
    NativeSelect,
    Button,
    FormControlLabel,
} from "@material-ui/core";
// import { Fetch_Payout_Sheet } from "../../api/private";
import MomentUtils from "@date-io/moment";
// import httpClientPrivate, {
//   httpListingClient,
// } from "../../api/httpClientPrivate";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
// import { useSelector } from "react-redux";
// import { useSnackbar } from "notistack";
import { useLocation, useHistory, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { fetch_payout } from "../api";
import axios from "axios";
import "../assets/payout-sheet.scss";

const past = new Date("January 1, 2019");

const headers = {
    partner_code: "Partner Code",
    partner_name: "Partner Name",
    property_name: "Property Name",
    booking_id: "Booking ID",
    listing_id: "Listing ID",
    state: "State",
    homeowner_gsti: "Homeowner GSTN",
    booking_date: "Booking Date",
    guest_name: "Guest Name",
    checkin_date: "Check In Date",
    checkout_date: "Check Out Date",
    no_of_guest: "No. of Guest",
    no_of_nights: "No. of Nights",
    booking_price_ex: "Total Booking Price excl GST",
    per_night_price: "Per Night Price",
    homeowner_name: "Home Owner Name",
    booking_status: "Booking Status",
    gst: "GST if any",
    ota_razorpay_comm: "OTA/Razorpay commission",
    gst_on_comm: "GST on OTA Commission",
    tds: "TDS/TCS deducted",
    net_amt_received: "Net amount Received",
    roamhome_income: "Roamhome's Income",
    gst_on_roamhome_income: "GST on Roamhome's Income",
    gst_govt: "GST to Gov",
    homeowner_amt: "Amount to Home Owner",
    ideal_payout_date: "Ideal Payout Date",
    payout_to_homeowner_date: "Payout to HO date",
    edited: "Edited",
    balance_amt: "Balance Amount",
    payout_amt: "Payout Amount",
    notes: "Notes",
    guest_ph: "Guest Phone No.",
    offline_payment: "Offline Payment",
    refund_amt: "Refund Amount",
    payout_status: "Payout Status",
    homeowner_email: "Homeowner Email",
};



const PayoutSheetPublic = ({ match }) => {
    //const [cookies, setCookie, removeCookie] = useCookies(["g-pwd"])
    const location = useLocation();
    const history = useNavigate(); //useHistory();

    const query = React.useMemo(() => new URLSearchParams(location.search), [location.search])

    const [payoutSheet, setPayoutSheet] = React.useState({
        firstCPayout: { payoutList: [] },
        secondCPayout: { payoutList: [] },
    });

    const [selectedListingId, setSelectedListingId] = React.useState(
        query.get("listing_id")
            ? query.get("listing_id") : null
    );

    const [token, setToken] = React.useState(
        query.get("token") ? query.get("token") : null
    );

    const [propertyName, setPropertyName] = React.useState(null)

    const [selectedMonth, setSelectedMonth] = React.useState(
        query.get("date")
            ? moment(query.get("date")).startOf("month")
            : moment().startOf("month")
    );

    const [activePayout, setActivePayout] = React.useState(null)

    const [first_payout_date, set_first_payout_date] = React.useState(
        moment(selectedMonth).set("date", 15)
    );
    const [second_payout_date, set_second_payout_date] = React.useState(
        moment(selectedMonth).set("date", 27)
    );

    const [selectedPayoutDate, setSelectedPayoutDate] = React.useState(
        moment(selectedMonth).set("date", 15)
    );

    // const { token } = useSelector((state) => state.auth);
    // const { enqueueSnackbar } = useSnackbar();

    //const token = cookies["g-pwd"];

    const displayFormat = (key, value) => {
        switch (key) {
            case "booking_date":
            case "checkin_date":
            case "checkout_date":
                return moment(value).format("DD MMM YYYY").toString();
            case "per_night_price":
            case "booking_price_ex":
            case "gst":
            case "ota_razorpay_comm":
            case "gst_on_comm":
            case "tds":
            case "net_amt_received":
            case "roamhome_income":
            case "gst_on_roamhome_income":
            case "gst_govt":
            case "homeowner_amt":
            case "balance_amt":
            case "payout_amt":
            case "refund_amt":
                return new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                }).format(value);
            case "edited":
                return value === true ? "true" : "false";
            default:
                return value;
        }
    };

    const hiddenFields = (key) => {
        switch (key) {
            case "listing_id":
            case "homeowner_email":
            case "partner_code":
            case "partner_name":
            case "state":
            case "homeowner_gsti":
            case "booking_price_ex":
            case "per_night_price":
            case "homeowner_name":
            case "gst":
            case "ota_razorpay_comm":
            case "gst_on_comm":
            case "tds":
            case "gst_govt":
            case "ideal_payout_date":
            case "edited":
            case "balance_amt":
            case "payout_amt":
            case "notes":
            case "offline_payment":
            case "guest_ph":
            case "refund_amt":
            case "payout_status":
                return true;
            default:
                return false;
        }
    };

    const [csvDownloadUrl, setCSVDownloadUrl] = React.useState(null);

    const generateCSV = () => {
        let csv = [];

        let shut = Object.entries(headers)
        let headers__ = shut.filter(([item, item_value]) => !hiddenFields(item)).map((item) => item[1])
        //let d = m.map((item) => item[1])
        //console.log("hh",m)
        
        csv.push(Object.values(headers__).join() + "\n");

        payoutSheet[
            first_payout_date.get("date") === selectedPayoutDate.get("date")
                ? "firstCPayout"
                : "secondCPayout"
        ].payoutList.forEach((item) => {
            console.log(item,'j')
            csv.push(
                Object.keys(headers)
                .filter((item) => !hiddenFields(item))
                    .map((header) => {
                        return `"${item[header]}"`;
                    })
                    .join() + "\n"
            );
        });

       
        // for (let item of payoutSheet[
        //     first_payout_date === selectedPayoutDate.get("date")
        //         ? "firstCPayout"
        //         : "secondCPayout"
        // ].payoutList) {
        //     csv.push(
        //         Object.keys(headers)
        //             .map((header) => {
        //                 return `"${item[header]}"`;
        //             })
        //             .join() + "\n"
        //     );
        // }

        const blob = new Blob(csv, { type: "text/csv" });

        // Creating an object for downloading url
        const url = window.URL.createObjectURL(blob);
        setCSVDownloadUrl(url);
    };

    const fetchPayoutSheet = () => {
        const data = {
            "token": token,
            "start_from": moment(selectedMonth).format("YYYY-MM-DD").toString(),
            ...(selectedListingId !== null || selectedListingId !== "null" && { 'listing_id': selectedListingId })
        }

        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: fetch_payout,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }


        axios(config)
            .then((response) => {
                //console.log(response);
                //let data = response.data.map((item) => {
                //  return formatData(item);
                //});
                
                selectedListingId !== null || selectedListingId !== "null" && setPropertyName(response.data.firstCPayout.length !== 0 ? response.data.firstCPayout.payoutList[0].property_name : response.data.secondCPayout.length !== 0 ? response.data.secondCPayout.payoutList[0].property_name : null)
                setPayoutSheet(response.data);
                set_first_payout_date(moment(response.data.firstCPayout.date));
                set_second_payout_date(moment(response.data.secondCPayout.date));
                setSelectedPayoutDate(moment(response.data.firstCPayout.date));
                
            })
            .catch(() => {
                console.log("Problem fetching data")
                alert("Issue Fetching Data")
            });


    }

    // const fetchPayoutSheet = () => {
    //     httpListingClient(
    //         Fetch_Payout_Sheet,
    //         {
    //             startFrom: moment(selectedMonth).format("YYYY-MM-DD").toString(),
    //             //bookingsMonthFrom: moment(selectedMonth)
    //             //  .format("YYYY/MM/DD")
    //             //  .toString(),
    //             //bookingsMonthTo: moment(selectedMonth)
    //             //  .add(3, "month")
    //             //  .endOf("month")
    //             //  .format("YYYY/MM/DD")
    //             //  .toString(),
    //         },
    //         "POST",
    //         token,
    //         "",
    //         "",
    //         "v2"
    //     )
    //         .then((response) => {
    //             console.log(response);
    //             //let data = response.data.map((item) => {
    //             //  return formatData(item);
    //             //});
    //             setPayoutSheet(response.data);
    //             set_first_payout_date(moment(response.data.firstCPayout.date));
    //             set_second_payout_date(moment(response.data.secondCPayout.date));
    //             setSelectedPayoutDate(moment(response.data.firstCPayout.date));
    //         })
    //         .catch(() => {
    //             enqueueSnackbar("Problem Fetching Payout Records.", {
    //                 variant: "error",
    //                 anchorOrigin: {
    //                     vertical: "top",
    //                     horizontal: "center",
    //                 },
    //             });
    //         });
    // };

    React.useEffect(() => {
        fetchPayoutSheet();
        selectedListingId === null && token === null ?
            history(`${location.pathname}?${new URLSearchParams({
                date: selectedMonth.format("YYYY-MM-DD").toString()
            }).toString()}&token=null`, { replace: true })
            :
            history(`${location.pathname}?${new URLSearchParams({
                date: selectedMonth.format("YYYY-MM-DD").toString()
            }).toString()}&${new URLSearchParams({
                listing_id: selectedListingId
            })}&${new URLSearchParams({
                token: token
            })}`, { replace: true })
        // history.replace({
        //     pathname: location.pathname,
        //     search:
        //         "?" +
        //         new URLSearchParams({
        //             date: selectedMonth.format("YYYY-MM-DD").toString(),
        //         }).toString(),
        // });
    }, [selectedMonth, selectedListingId]);

    React.useEffect(() => {
        // if(payoutSheet.firstCPayout.date) {
            generateCSV();
        // }
    }, [payoutSheet])

    React.useEffect(() => {
        if (activePayout !== null) {
           // generateCSV();
        }
    }, [activePayout]);

    return (
        <Grid container className="payout-sheet">
            <Grid className="payout-top" style={{ marginBottom: 40 }} item xs={12}>
                <Grid container>
                    <Grid item xs>
                        <div className="">Payouts {propertyName !== null  && `for ${propertyName}`}</div>
                        <div className="text-bold" style={{ marginBottom: 10 }}>
                            {moment(selectedMonth).format("MMM YYYY")}
                        </div>

                        <div className="date-group">
                            <button
                                className={`first-btn ${selectedPayoutDate.get("date") ===
                                    first_payout_date.get("date")
                                    ? "active"
                                    : ""
                                    }`}
                                onClick={() => setSelectedPayoutDate(moment(first_payout_date))}
                            >
                                {first_payout_date.format("DD MMM")}
                            </button>
                            |
                            <button
                                className={`second-btn ${selectedPayoutDate.get("date") ===
                                    second_payout_date.get("date")
                                    ? "active"
                                    : ""
                                    }`}
                                onClick={() =>
                                    setSelectedPayoutDate(moment(second_payout_date))
                                }
                            >
                                {second_payout_date.format("DD MMM")}
                            </button>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Grid
                            container
                            style={{ height: "100%" }}
                            direction="column"
                            justifyContent="space-between"
                            alignItems="flex-end"
                        >
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DatePicker
                                    openTo="year"
                                    minDate={past}
                                    views={["month", "year"]}
                                    value={selectedMonth}
                                    onChange={setSelectedMonth}
                                    inputVariant="filled"
                                    TextFieldComponent={(props) => {
                                        return (
                                            <button
                                                type="button"
                                                onClick={props.onClick}
                                                value={props.value}
                                                onChange={setSelectedMonth}
                                                className="btn btn-primary"
                                            //onClick={() => setShowAddBookingRecordDialog(true)}
                                            >
                                                Select Month
                                            </button>
                                        );
                                    }}
                                ></DatePicker>
                            </MuiPickersUtilsProvider>

                            <a
                                className="btn btn-secondary"
                                href={csvDownloadUrl}
                                relative="path"
                                target="_blank"
                                download={`${selectedPayoutDate.format(
                                    "YYYY-MM-DD"
                                )}-payout-sheet.csv`}
                            >
                                Download {selectedPayoutDate.format("DD MMM YYYY")} Payout Sheet
                            </a>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {payoutSheet &&
                (first_payout_date.get("date") === selectedPayoutDate.get("date")
                    ? payoutSheet.firstCPayout.payoutList.length
                    : payoutSheet.secondCPayout.payoutList.length) > 0 && (
                    <Grid className="table" item xs={12}>
                        <div className="table-header">
                            {Object.keys(headers)
                                .filter((item) => !hiddenFields(item))
                                .map((item, index) => {
                                    return (
                                        <div
                                            className="table-header-cell"
                                            key={index}
                                            style={{
                                                flexBasis: item === "property_name" ? "20%" : "10%",
                                            }}
                                        >
                                            {headers[item]}
                                        </div>
                                    );
                                })}
                        </div>

                        {payoutSheet[
                            first_payout_date.get("date") === selectedPayoutDate.get("date")
                                ? "firstCPayout"
                                : "secondCPayout"
                        ].payoutList
                            .sort((a, b) => {
                                let emailA = a.homeowner_email.toLowerCase();
                                let emailB = b.homeowner_email.toLowerCase();
                                if (emailA < emailB) return -1;
                                if (emailA > emailB) return 1;
                                return 0;
                            })
                            .map((row, index) => {
                                return (
                                    <div
                                        className="table-row"
                                        key={index}
                                        onDoubleClick={() => {
                                            // setSelectedBookingRecord(row);
                                            // setShowEditBookingRecordDialog(true);
                                        }}
                                    >
                                        {Object.keys(headers)
                                            .filter((item) => !hiddenFields(item))
                                            .map((item, item_index) => {
                                                return (
                                                    <div
                                                        className="table-row-cell"
                                                        style={{
                                                            flexBasis:
                                                                item === "property_name" ? "20%" : "10%",
                                                            backgroundColor:
                                                                row["booking_status"] === "cancelled"
                                                                    ? "#f8d7da"
                                                                    : "white",
                                                        }}
                                                        key={item_index}
                                                    >
                                                        <abbr title={row[item]}>
                                                            {displayFormat(item, row[item])}
                                                        </abbr>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                );
                            })}
                    </Grid>
                )}
        </Grid>
    );
};

export default PayoutSheetPublic;
